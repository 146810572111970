import * as React from 'react'

import { Add } from '@mui/icons-material'
import { Button, Fab, Stack } from '@mui/material'

import { Dialog } from 'shared/components'
import { Fund } from 'shared/queries'

import { FundPurchaseCreator } from './fund_purchase_creator'
import { FundSaleCreator } from './fund_sale_creator'

type StepKey = 'OPERATION_SELECT' | 'PURCHASE' | 'SALE'

const STEP_LABELS: { [key in StepKey]: string } = {
  OPERATION_SELECT: 'Agregar nueva operación',
  PURCHASE: 'Agregar compra de usuario',
  SALE: 'Agregar venta de usuario',
}

type FundOperationCreatorProps = {
  fund: Fund
  userId: string
}

export const FundOperationCreator = ({
  fund,
  userId,
}: FundOperationCreatorProps) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState<StepKey>('OPERATION_SELECT')

  const cancelCreate = () => setActiveStep('OPERATION_SELECT')

  const openDialog = () => {
    cancelCreate()
    setDialogOpen(true)
  }

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <Fab
        color='primary'
        aria-label='Crear operación'
        onClick={openDialog}
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(4),
        })}
      >
        <Add />
      </Fab>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title={STEP_LABELS[activeStep]}
        maxWidth='xs'
      >
        {(() => {
          switch (activeStep) {
          case 'OPERATION_SELECT': return (
            <Stack spacing={3}>
              <Button
                onClick={() => setActiveStep('PURCHASE')}
                variant='contained'
                size='large'
              >
                Agregar una compra
              </Button>
              <Button
                onClick={() => setActiveStep('SALE')}
                variant='contained'
                size='large'
              >
                Agregar una venta
              </Button>
            </Stack>
          )
          case 'PURCHASE' : return (
            <FundPurchaseCreator
              cancelCreate={cancelCreate}
              closeDialog={closeDialog}
              fund={fund}
              userId={userId}
            />
          )
          case 'SALE' : return (
            <FundSaleCreator
              cancelCreate={cancelCreate}
              closeDialog={closeDialog}
              fund={fund}
              userId={userId}
            />
          )
          }
        })()}
      </Dialog>
    </React.Fragment>
  )
}
