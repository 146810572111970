import * as React from 'react'

import { useQuery } from '@apollo/client'
import { ListItem, ListItemText } from '@mui/material'

import { findCountryByCode } from 'shared/services'

import SpouseIdentityEditForm from './spouse_identity_edit_form'
import { SPOUSE_IDENTITY_QUERY } from '../../../../queries/spouse_identities'
import ListDisplay from '../list_display'
import ListLoading from '../list_loading'

import type {
  SpouseIdentityData,
  SpouseIdentityVars,
} from '../../../../queries/spouse_identities'
import type { PersonIdentity, SpouseIdentity } from 'shared/queries'

type SpouseIdentityDetailsDisplayProps = {
  spouseIdentity: SpouseIdentity
}

const SpouseIdentityDetailsDisplay = ({
  spouseIdentity,
}: SpouseIdentityDetailsDisplayProps) => (
  <React.Fragment>
    <ListItem divider>
      <ListItemText
        primary='Nombre(s)'
        secondary={spouseIdentity.givenName}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Apellido(s)'
        secondary={spouseIdentity.familyName}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Fecha de nacimiento'
        secondary={spouseIdentity.birthdate}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Nacionalidad'
        secondary={(
          findCountryByCode(spouseIdentity.nationality)?.name
            || spouseIdentity.nationality
        )}
      />
    </ListItem>
    <ListItem>
      <ListItemText
        primary={spouseIdentity.nationality === 'CL' ? 'RUT' : 'Número de identificación'}
        secondary={spouseIdentity.identificationNumber}
      />
    </ListItem>
  </React.Fragment>
)

type SpouseIdentityDisplayProps = {
  userId: string
  personIdentity: PersonIdentity
}

const SpouseIdentityDisplay = ({
  userId,
  personIdentity,
}: SpouseIdentityDisplayProps) => {
  const { loading, data } =
    useQuery<SpouseIdentityData, SpouseIdentityVars>(SPOUSE_IDENTITY_QUERY, {
      variables: {
        personIdentityId: personIdentity.id,
      },
    })

  return (loading) ? (
    <ListLoading />
  ) : (
    <ListDisplay
      title='Cónyugue'
      item={data?.spouseIdentity}
      editForm={(closeDialog) => (
        <SpouseIdentityEditForm
          closeDialog={closeDialog}
          userId={userId}
          personIdentityId={personIdentity.id}
          spouseIdentity={data?.spouseIdentity}
        />
      )}
    >
      {(item) => <SpouseIdentityDetailsDisplay spouseIdentity={item} />}
    </ListDisplay>
  )
}

export default SpouseIdentityDisplay
