import * as React from 'react'

import { useMutation } from '@apollo/client'
import { PieChart } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'
import { Form, Formik } from 'formik'

import { ButtonContainer, ButtonsContainer, Dialog, ErrorDisplay } from 'shared/components'
import {
  FundCompositionFields,
  getFundCompositionInitialValues as getInitialValues,
  validTotalPercentage,
  fundCompositionValidationSchema as validationSchema,
} from 'shared/forms'
import { setFormError } from 'shared/services'

import { FUND_DETAILS_QUERY, UPDATE_FUND_MUTATION } from '../queries/funds'
import { translateGuitaError } from '../services/error_messages'

import type { FundDetails, UpdateFundData, UpdateFundVars } from '../queries/funds'
import type { FormikProps } from 'formik'
import type { FundCompositionFormValues as FormValues } from 'shared/forms'

type InnerFormProps = FormikProps<FormValues> & {
  closeDialog: () => void
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  values,
  closeDialog,
}: InnerFormProps) => (
  <Form>
    <FundCompositionFields />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting}
          onClick={closeDialog}
          variant='outlined'
          color='secondary'
        >
          Cancelar
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid || !validTotalPercentage(values.percentages)}
          onClick={submitForm}
          variant='contained'
          color='error'
        >
          Modificar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type FundUpdaterProps= {
  fund: FundDetails
}

export const FundUpdater = ({
  fund,
}: FundUpdaterProps) => {
  const fundId = fund.id
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const [updateFund] =
    useMutation<UpdateFundData, UpdateFundVars>(UPDATE_FUND_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        { query: FUND_DETAILS_QUERY, variables: { fundId } },
      ],
    })

  const handleSubmit = async (values: FormValues) => {
    const response = await updateFund({
      variables: {
        fundId,
        assets: values.assets,
        percentages: values.percentages.map((percentage) => percentage.toString()),
      },
    })

    if (response.data?.updateFund === 'OK!') {
      closeDialog()
      return
    }

    setFormError(formRef, translateGuitaError(response))
  }

  return (
    <React.Fragment>
      <IconButton
        onClick={openDialog}
        color='inherit'
      >
        <PieChart />
      </IconButton>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title={`Modificar composición: ${fund.name}`}
      >
        <Formik
          innerRef={formRef}
          initialValues={getInitialValues(fund)}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <InnerForm
              {...props}
              closeDialog={closeDialog}
            />
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  )
}
