import * as React from 'react'

import { useQuery } from '@apollo/client'

import { Loading, SeoHeaders } from 'shared/components'
import { FundsView } from 'shared/views'

import { FUNDS_QUERY } from '../../queries/funds'
import { FundCreator } from '../fund_creator'

import type { FundsData, FundsVars } from '../../queries/funds'
import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MainFunds = (props: RouteComponentProps) => {
  const { loading, data } =
    useQuery<FundsData, FundsVars>(FUNDS_QUERY, {
      fetchPolicy: 'network-only',
    })

  const funds = data?.funds.funds || []
  const totalBalance = data?.funds.totalBalance || 0

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <SeoHeaders title='Fondos' />
      <FundsView
        funds={funds}
        totalBalance={totalBalance}
        mainFundsView
      />
      <FundCreator />
    </React.Fragment>
  )
}

export default MainFunds
