import * as React from 'react'

import { InputAdornment, Stack } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import { CheckboxWithLabel } from 'formik-mui'
import * as Yup from 'yup'

import { CurrencyField } from 'shared/forms'
import { Fund } from 'shared/queries'
import { isValidAssetAmount } from 'shared/services'

export type FundTransactionFormValues = {
  fiatAmount: number
  sharesAmount: number
  notifyUser: boolean
}

export const fundTransactionInitialValues: FundTransactionFormValues = ({
  fiatAmount: 0,
  sharesAmount: 0,
  notifyUser: true,
})

export const getFundTransactionMutationVariables =
  (userId: string, data: FundTransactionFormValues) => ({
    userId,
    fiatAmount: data.fiatAmount.toString(),
    sharesAmount: data.sharesAmount.toString(),
    notifyUser: data.notifyUser,
  })

export const fundTransactionValidationSchema: Yup.SchemaOf<FundTransactionFormValues> = (
  Yup.object().shape({
    fiatAmount: Yup.number()
      .required('El monto es obligatorio')
      .positive('Introduce un número superior a 0')
      .test(
        'validFormat',
        'Introduce un número con máximo 6 decimales',
        (value) => isValidAssetAmount(value),
      ),
    sharesAmount: Yup.number()
      .required('El monto es obligatorio')
      .positive('Introduce un número superior a 0')
      .test(
        'validFormat',
        'Introduce un número con máximo 6 decimales',
        (value) => isValidAssetAmount(value),
      ),
    notifyUser: Yup.boolean()
      .required('Este campo es obligatorio'),
  })
)

type FundTransactionProps = {
  fund: Fund
  children?: React.ReactNode
}

export const FundTransactionFields = ({
  fund,
  children,
}: FundTransactionProps) => {
  const { setFieldTouched, setFieldValue } = useFormikContext<FundTransactionFormValues>()

  const onFiatAmountChange = (newAmount?: number) => {
    const fiatAmount = newAmount || 0
    setFieldValue('sharesAmount', (fiatAmount / fund.sharePrice).toFixed(6))
    setFieldTouched('sharesAmount', false, false)
  }

  return (
    <Stack spacing={3}>
      <CurrencyField
        name='fiatAmount'
        label='Monto'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <small>USD</small>&nbsp;$
            </InputAdornment>
          ),
        }}
        onChange={onFiatAmountChange}
        digits={2}
        positive
      />
      <CurrencyField
        name='sharesAmount'
        label='Cuotas'
        digits={6}
        positive
      />
      {children}
      <Field
        required
        component={CheckboxWithLabel}
        type='checkbox'
        name='notifyUser'
        Label={{ label: 'Notificar por mail al usuario' }}
      />
    </Stack>
  )
}
