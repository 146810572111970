import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Typography } from '@mui/material'

import { Loading, SeoHeaders } from 'shared/components'
import { asOperationsArray } from 'shared/services'
import { FundDetailsView } from 'shared/views'

import { FundOperationCreator } from './fund_operation_creator'
import { FUND_QUERY } from '../../../queries/funds'
import { USER_OPERATIONS_QUERY } from '../../../queries/user_operations'

import type { FundData, FundVars } from '../../../queries/funds'
import type {
  UserOperationsData,
  UserOperationsVars,
} from '../../../queries/user_operations'
import type { RouteComponentProps } from '@reach/router'

type FundDetailsProps = RouteComponentProps & {
  id?: string
  userId?: string
}

const FundsDetails = (props: FundDetailsProps) => {
  const fundId = props.id || ''
  const userId = props.userId || ''

  const { loading: fundLoading, data: fundData } = useQuery<FundData, FundVars>(FUND_QUERY, {
    variables: {
      fundId,
      userId,
    },
  })

  const { loading: operationsLoading, data: operationsData } =
    useQuery<UserOperationsData, UserOperationsVars>(USER_OPERATIONS_QUERY, {
      variables: {
        userId,
      },
    })

  if (fundLoading || operationsLoading) {
    return <Loading />
  }

  const fund = fundData?.fund
  const operations = asOperationsArray(operationsData?.userOperations)

  return fund ? (
    <React.Fragment>
      <SeoHeaders title={`Fondos - ${userId}(${fund.name})`} />
      <FundDetailsView
        fund={fund}
        operations={operations}
      />
      <FundOperationCreator
        fund={fund}
        userId={userId}
      />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <SeoHeaders title='Fondo no encontrado' />
      <Typography
        variant='h5'
        textAlign='center'
      >
        Fondo no encontrado
      </Typography>
    </React.Fragment>
  )
}

export default FundsDetails
