import * as React from 'react'

import {
  ListItem,
  ListItemText,
} from '@mui/material'

import { DocumentDisplay } from 'shared/components'
import { POI_DOCUMENT_TYPE_LABELS } from 'shared/queries'

import IdentityProofDeleteForm from './identity_proof_delete_form'
import IdentityProofEditForm from './identity_proof_edit_form'
import ListDisplay from './list_display'

import type { IdentityProofView } from 'shared/queries'

type IdentityProofDetailsDisplayProps = {
  identityProof: IdentityProofView
}

const IdentityProofDetailsDisplay = ({
  identityProof,
}: IdentityProofDetailsDisplayProps) => (
  <React.Fragment>
    <ListItem divider>
      <ListItemText
        primary='Fecha de expiración'
        secondary={identityProof.expirationDate || '<Sin información>'}
      />
    </ListItem>
    <ListItem>
      <DocumentDisplay
        contentType={identityProof.contentType}
        documentType={identityProof.documentType}
        storageUrl={identityProof.storageUrl}
      />
    </ListItem>
  </React.Fragment>
)

type IdentityProofDisplayProps = {
  userId: string
  isBusiness: boolean
  identityProof: IdentityProofView
}

const IdentityProofDisplay = ({
  userId,
  isBusiness,
  identityProof,
}: IdentityProofDisplayProps) => (
  <ListDisplay
    title={POI_DOCUMENT_TYPE_LABELS[identityProof.documentType]}
    item={identityProof}
    deleteForm={(closeDialog) => (
      <IdentityProofDeleteForm
        closeDialog={closeDialog}
        userId={userId}
        documentId={identityProof.id}
      />
    )}
    editForm={(closeDialog) => (
      <IdentityProofEditForm
        closeDialog={closeDialog}
        userId={userId}
        isBusiness={isBusiness}
        identityProof={identityProof}
      />
    )}
  >
    {(item) => <IdentityProofDetailsDisplay identityProof={item} />}
  </ListDisplay>
)

export default IdentityProofDisplay
