import * as React from 'react'

import { InputAdornment, Stack, Typography } from '@mui/material'
import { Field, getIn, useFormikContext } from 'formik'
import { DesktopDateTimePicker } from 'formik-mui-x-date-pickers'
import * as Yup from 'yup'

import { AssetBadge, Currency } from 'shared/components'
import { BaseCurrencyField } from 'shared/forms'
import { FUND_ASSET_LABELS, Fund } from 'shared/queries'
import { isValidAssetAmount, toISO8601DateTime } from 'shared/services'

import { FundDetails } from '../queries/funds'
import { getCurrentAssetAmount } from '../services/funds'

export type FundOperationFormValues = {
  amounts: number[]
  assets: string[]
  timestamp: Date | null
}

export const getFundOperationInitialValues = (assets: string[]): FundOperationFormValues => ({
  amounts: new Array(assets.length).fill(0),
  assets: assets,
  timestamp: null,
})

export const getFundOperationMutationVariables =
  (fund: Fund, data: FundOperationFormValues) => ({
    fundId: fund.id,
    amounts: data.amounts.map((amount) => amount.toString()),
    assets: data.assets,
    timestamp: toISO8601DateTime(data.timestamp),
  })

export const fundOperationValidationSchema =
  (positive?: boolean): Yup.SchemaOf<FundOperationFormValues> => (
    Yup.object().shape({
      amounts: Yup.array()
        .of(Yup.number()
          .required('El monto es obligatorio')
          .test(
            'validNumber',
            'Introduce un número superior a 0',
            (value) => {
              if (value === undefined || positive === undefined) {
                return true
              }

              return value > 0
            },
          )
          .test(
            'validFormat',
            'Introduce un número con máximo 6 decimales',
            (value) => isValidAssetAmount(value),
          )),
      assets: Yup.array()
        .of(Yup.string()
          .required('Este campo es obligatorio'))
        .min(1, 'Selecciona al menos un activo'),
      timestamp: Yup.date()
        .required('Este campo es obligatorio'),
    })
  )

type AmountFieldProps = {
  index: number
  positive?: boolean
}

const AmountField = ({
  index,
  positive,
}: AmountFieldProps) => {
  const { errors, setFieldTouched, setFieldValue, touched, values } =
    useFormikContext<FundOperationFormValues>()

  const amountError = getIn(errors, `amounts.${index}`)
  const amountTouched = getIn(touched, `amounts.${index}`)

  const updateAssetAmount = (newValue: number) => {
    setFieldValue(`amounts.${index}`, newValue)
  }

  return (
    <BaseCurrencyField
      required
      positive={positive}
      label='Monto'
      name={`amounts.${index}`}
      value={values.amounts[index]}
      error={amountTouched && !!amountError}
      helperText={amountTouched &&  amountError}
      onBlur={() => setFieldTouched(`amounts.${index}`, true)}
      onChange={(newValue?: number) => newValue && updateAssetAmount(newValue)}
      digits={6}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <small>{values.assets[index]}</small>
          </InputAdornment>
        ),
      }}
      sx={{ flexGrow: 1 }}
    />
  )
}

type FundOperationFieldsProps = {
  fund?: FundDetails
  positive?: boolean
  children?: React.ReactNode
}

export const FundOperationFields = ({
  fund,
  positive,
  children,
}: FundOperationFieldsProps) => {
  const { values } = useFormikContext<FundOperationFormValues>()

  return (
    <Stack spacing={3}>
      {values.assets.map((_, index) => (
        <Stack
          key={index}
          direction='row'
          alignItems='center'
          spacing={2}
        >
          <Stack sx={{ flexGrow: 1, minWidth: '20%' }}>
            <AssetBadge
              symbol={values.assets[index]}
              height={20}
            />
            <Typography textAlign='center'>
              {FUND_ASSET_LABELS[values.assets[index]]}
            </Typography>
          </Stack>
          <AmountField
            index={index}
            positive={positive}
          />
          {fund && (
            <Stack
              justifyContent='center'
              alignItems='center'
              sx={{ flexGrow: 1, minWidth: '30%' }}
            >
              <small><strong>Disponible</strong></small>
              <Currency
                currency={''}
                value={getCurrentAssetAmount(fund, values.assets[index])}
                digits={6}
              />
            </Stack>
          )}
        </Stack>
      ))}
      {children}
      <Field
        required
        name='timestamp'
        type='date'
        label='Fecha y hora de confirmación (local)'
        component={DesktopDateTimePicker}
        views={['year', 'month', 'day']}
        inputFormat='yyyy/MM/dd HH:mm:ss'
        toolbarTitle='Fecha y hora de confirmación'
        textField={{ fullWidth: true, margin: 'normal' }}
        closeOnSelect
        disableFuture
      />
    </Stack>
  )
}
