import * as React from 'react'

import { Download, SwapCalls, Upload } from '@mui/icons-material'
import { ListItem, ListItemText, Stack, Typography } from '@mui/material'
import { navigate } from '@reach/router'

import {
  Currency,
  DatedList,
  DatedListItem,
  FundPurchaseDisplay,
  FundSaleDisplay,
} from 'shared/components'
import { FundPurchase, FundSale } from 'shared/queries'

import {
  AllFundOperation,
  FundDeposit,
  FundOperation,
  FundSwap,
  FundWithdrawal,
} from '../queries/fund_operations'
import { FundDetails } from '../queries/funds'

const MovementsDisplay = ({ operation }: { operation: FundOperation }) => {
  const isWithdrawal = operation.__typename === 'FundWithdrawal'

  return (
    <Stack>
      {operation.assets.map((asset, index) => (
        <Typography
          key={index}
          textAlign='right'
        >
          <Currency
            currency={asset}
            digits={6}
            value={isWithdrawal ? -operation.amounts[index] : operation.amounts[index]}
          />
        </Typography>
      ))}
    </Stack>
  )
}

const FundDepositDisplay = ({
  operation,
}: OperationDisplay<FundDeposit>) => (
  <DatedListItem
    icon={<Download />}
    text={'Depósito'}
    timestamp={operation.timestamp}
  >
    <MovementsDisplay operation={operation} />
  </DatedListItem>
)

type OperationDisplay<OperationType> = {
  operation: OperationType
}

const FundSwapDisplay = ({
  operation,
}: OperationDisplay<FundSwap>) => (
  <DatedListItem
    icon={<SwapCalls />}
    text={'Rebalanceo'}
    timestamp={operation.timestamp}
  >
    <MovementsDisplay operation={operation} />
  </DatedListItem>
)

const FundWithdrawalDisplay = ({
  operation,
}: OperationDisplay<FundWithdrawal>) => (
  <DatedListItem
    icon={<Upload />}
    text={'Retiro'}
    timestamp={operation.timestamp}
  >
    <MovementsDisplay operation={operation} />
  </DatedListItem>
)

const getOperationComponent = (operation: AllFundOperation, fundId: string) => {
  const handleOpClick = (op: FundPurchase | FundSale) => {
    navigate(`/app/users/${op.userId}/funds/${fundId}`)
  }

  switch (operation.__typename) {
  case 'FundDeposit':
    return (
      <FundDepositDisplay operation={operation} />
    )
  case 'FundSwap':
    return (
      <FundSwapDisplay operation={operation} />
    )
  case 'FundWithdrawal':
    return (
      <FundWithdrawalDisplay operation={operation} />
    )
  case 'FundPurchase':
    return (
      <FundPurchaseDisplay
        withUserId
        operation={operation}
        onClick={() => handleOpClick(operation)}
      />
    )
  case 'FundSale':
    return (
      <FundSaleDisplay
        withUserId
        operation={operation}
        onClick={() => handleOpClick(operation)}
      />
    )
  }
}

const EmptyOperationsDisplay = () => (
  <ListItem>
    <ListItemText
      primary='No hay operaciones para mostrar'
      primaryTypographyProps={{ color: 'text.secondary', textAlign: 'center' }}
    />
  </ListItem>
)

type FundOperationsListProps = {
  operations: AllFundOperation[]
  fund: FundDetails
}

export const FundOperationsList = ({
  operations,
  fund,
}: FundOperationsListProps) => (
  <DatedList
    emptyListDisplay={<EmptyOperationsDisplay />}
    items={operations.map((op) => ({
      timestamp: op.timestamp,
      component: getOperationComponent(op, fund.id),
    }))}
  />
)
