import * as React from 'react'

import { ListItem, ListItemText } from '@mui/material'

import { civilStateStr } from 'shared/queries'
import { findCountryByCode } from 'shared/services'

import ListDisplay from './list_display'
import PersonIdentityEditForm from './person_identity_edit_form'

import type { OnboardingType } from '../../../queries/domains'
import type { PersonIdentity } from 'shared/queries'

type PersonIdentityDetailsDisplayProps = {
  userEmail?: string
  personIdentity: PersonIdentity
}

const PersonIdentityDetailsDisplay = ({
  userEmail,
  personIdentity,
}: PersonIdentityDetailsDisplayProps) => (
  <React.Fragment>
    {(typeof userEmail !== 'undefined') && (
      <ListItem divider>
        <ListItemText
          primary='Correo electrónico'
          secondary={userEmail}
        />
      </ListItem>
    )}
    <ListItem divider>
      <ListItemText
        primary='Nombre(s)'
        secondary={personIdentity.givenName}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Apellido(s)'
        secondary={personIdentity.familyName}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Fecha de nacimiento'
        secondary={personIdentity.birthdate}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Nacionalidad'
        secondary={(
          findCountryByCode(personIdentity.nationality)?.name
          || personIdentity.nationality
          || 'No disponible'
        )}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary={personIdentity.nationality === 'CL' ? 'RUT' : 'Número de identificación'}
        secondary={personIdentity.identificationNumber}
      />
    </ListItem>
    <ListItem divider>
      <ListItemText
        primary='Estado civil'
        secondary={civilStateStr(personIdentity.civilState)}
      />
    </ListItem>
    <ListItem>
      <ListItemText
        primary='Es Persona Expuesta Políticamente (PEP)'
        secondary={personIdentity.isPoliticallyExposed ? 'Sí' : 'No'}
      />
    </ListItem>
  </React.Fragment>
)

type PersonIdentityDisplayProps = {
  userEmail?: string
  userId: string
  personIdentity?: PersonIdentity
  onboardingType: OnboardingType
}

const PersonIdentityDisplay = ({
  userEmail,
  userId,
  personIdentity,
  onboardingType,
}: PersonIdentityDisplayProps) => (
  <ListDisplay
    title='Información básica'
    item={personIdentity}
    editForm={(closeDialog) => (
      <PersonIdentityEditForm
        closeDialog={closeDialog}
        userId={userId}
        personIdentity={personIdentity}
        onboardingType={onboardingType}
      />
    )}
  >
    {(item) => (
      <PersonIdentityDetailsDisplay
        userEmail={userEmail}
        personIdentity={item}
      />
    )}
  </ListDisplay>
)

export default PersonIdentityDisplay
