import * as React from 'react'

import { useMutation } from '@apollo/client'
import {
  Button,
  MenuItem,
  Stack,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Field, Form, Formik } from 'formik'
import { Select } from 'formik-mui'
import * as Yup from 'yup'

import { ButtonContainer, ButtonsContainer, ErrorDisplay } from 'shared/components'
import {
  BaseIdentityFields,
  BooleanField,
  baseIdentityValidationSchema,
  getBaseIdentityInitialValues,
} from 'shared/forms'
import { CIVIL_STATE_LABELS } from 'shared/queries'
import { booleanToString, setFormError, stringToBoolean, toISO8601Date } from 'shared/services'

import { UPDATE_PERSON_IDENTITY_MUTATION } from '../../../queries/person_identities'
import { USER_QUERY } from '../../../queries/users'
import { translateGuitaError } from '../../../services/error_messages'

import type { OnboardingType } from '../../../queries/domains'
import type {
  UpdatePersonIdentityData,
  UpdatePersonIdentityVars,
} from '../../../queries/person_identities'
import type { FormikProps } from 'formik'
import type { ExecutionResult } from 'graphql'
import type { BaseIdentityFormValues } from 'shared/forms'
import type { PersonIdentity } from 'shared/queries'

type FormValues = {
  civilState?: string
  isPoliticallyExposed: string
} & BaseIdentityFormValues

const getInitialValues = (data?: PersonIdentity): FormValues => ({
  civilState: data?.civilState || 'UNDECLARED',
  isPoliticallyExposed: booleanToString(data?.isPoliticallyExposed),
  ...getBaseIdentityInitialValues(data),
})

const getMutationVariables = (data: FormValues, userId: string): UpdatePersonIdentityVars => ({
  birthdate: toISO8601Date(data.birthdate),
  familyName: data.familyName,
  givenName: data.givenName,
  identificationNumber: data.identificationNumber || '',
  nationality: data.nationality,
  civilState: data.civilState === 'UNDECLARED' ? '' : data.civilState,
  isPoliticallyExposed: stringToBoolean(data.isPoliticallyExposed),
  userId,
})

const validationSchema = (onboardingType: OnboardingType): Yup.SchemaOf<FormValues> =>
  Yup.object().shape({
    civilState: (onboardingType === 'ADVANCED')
      ? Yup.string()
        .notOneOf(['UNDECLARED'], 'Debe declarar un estado civil')
        .required('Este campo es obligatorio')
      : Yup.string()
        .required('Este campo es obligatorio'),
    isPoliticallyExposed: Yup.string()
      .oneOf(['true', 'false'], 'Debe seleccionar una opción')
      .required('Debe seleccionar una respuesta'),
    ...baseIdentityValidationSchema.fields,
  })

type InnerFormProps = FormikProps<FormValues> & {
  isPresent: boolean
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  isPresent,
}: InnerFormProps) => (
  <Form>
    <Stack spacing={3}>
      <BaseIdentityFields />
      <Field
        name='civilState'
        type='text'
        label='Estado civil'
        component={Select}
        fullWidth
      >
        <MenuItem value='UNDECLARED'>
          Sin declarar
        </MenuItem>
        {Object.entries(CIVIL_STATE_LABELS).map(([stateType, stateLabel]) => (
          <MenuItem
            key={stateType}
            value={stateType}
          >
            {stateLabel}
          </MenuItem>
        ))}
      </Field>
      <BooleanField
        label='¿El usuario es o ha sido una Persona Expuesta Políticamente (PEP)?'
        name='isPoliticallyExposed'
      />
    </Stack>
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='error'
        >
          {isPresent ? 'Modificar' : 'Crear'}
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type PersonIdentityEditFormProps = {
  closeDialog: () => void
  userId: string
  personIdentity?: PersonIdentity
  onboardingType: OnboardingType
}

export const PersonIdentityEditForm = ({
  closeDialog,
  userId,
  personIdentity,
  onboardingType,
}: PersonIdentityEditFormProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [updatePersonIdentity] =
    useMutation<UpdatePersonIdentityData, UpdatePersonIdentityVars>(
      UPDATE_PERSON_IDENTITY_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          { query: USER_QUERY, variables: { userId } },
        ],
      })

  const handleResponse = (response: ExecutionResult, data?: string) => {
    if (data === 'OK!') {
      return closeDialog()
    }

    setFormError(formRef, translateGuitaError(response))
  }

  const handleSubmit = async (values: FormValues) => {
    const variables = getMutationVariables(values, userId)

    const response = await updatePersonIdentity({ variables })
    handleResponse(response, response.data?.updatePersonIdentity)
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues(personIdentity)}
        validationSchema={validationSchema(onboardingType)}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm
            {...props}
            isPresent={!!personIdentity}
          />
        )}
      </Formik>
    </LocalizationProvider>
  )
}

export default PersonIdentityEditForm
