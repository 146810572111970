import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Alert, Button, DialogContentText, Stack } from '@mui/material'

import { ErrorDisplay } from 'shared/components'

import { DELETE_IDENTITY_PROOF_MUTATION } from '../../../queries/identity_proofs'
import { USER_QUERY } from '../../../queries/users'
import { translateGuitaError } from '../../../services/error_messages'

import type {
  DeleteIdentityProofData,
  DeleteIdentityProofVars,
} from '../../../queries/identity_proofs'

type IdentityProofDeleteFormProps = {
  closeDialog: () => void
  userId: string
  documentId: string
}

const IdentityProofDeleteForm = ({
  closeDialog,
  userId,
  documentId,
}: IdentityProofDeleteFormProps) => {
  const [updating, setUpdating] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()

  const [deleteIdentityProof] =
    useMutation<DeleteIdentityProofData, DeleteIdentityProofVars>(DELETE_IDENTITY_PROOF_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        { query: USER_QUERY, variables: { userId } },
      ],
    })

  const handleConfirm = async () => {
    setUpdating(true)
    const response = await deleteIdentityProof({
      variables: {
        id: documentId,
        userId: userId,
      },
    })
    setUpdating(false)

    if (response.data?.deleteIdentityProof) {
      closeDialog()
      return
    }

    setErrorMsg(translateGuitaError(response))
  }

  return (
    <Stack spacing={2}>
      <DialogContentText id='alert-dialog-description'>
        Esta acción <strong>NO</strong> se puede revertir.
      </DialogContentText>
      <Alert severity='info'>
        Si eliminas un carnet de identidad, debes eliminar manualmente ambas caras.
      </Alert>
      <ErrorDisplay errorMsg={errorMsg} />
      <Button
        color='error'
        variant='contained'
        onClick={handleConfirm}
        disabled={updating}
        fullWidth
      >
        Eliminar
      </Button>
    </Stack>
  )
}

export default IdentityProofDeleteForm
