import * as React from 'react'

import { useQuery } from '@apollo/client'

import { Loading, SeoHeaders } from 'shared/components'
import { FundsView } from 'shared/views'

import { FUNDS_QUERY } from '../../queries/funds'

import type { FundsData, FundsVars } from '../../queries/funds'
import type { RouteComponentProps } from '@reach/router'

type FundsProps = RouteComponentProps & {
  userId?: string
}

const Funds = (props: FundsProps) => {
  const userId = props.userId || ''
  const { loading, data } = useQuery<FundsData, FundsVars>(FUNDS_QUERY, {
    variables: {
      userId,
    },
    fetchPolicy: 'network-only',
  })

  const funds = data?.funds.funds || []
  const totalBalance = data?.funds.totalBalance || 0

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <SeoHeaders title={`Fondos - ${userId}`} />
      <FundsView
        funds={funds}
        totalBalance={totalBalance}
      />
    </React.Fragment>
  )
}

export default Funds
