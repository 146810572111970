import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button, Divider, Stack } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel } from 'formik-mui'
import * as Yup from 'yup'

import { ButtonContainer, ButtonsContainer, ErrorDisplay } from 'shared/components'
import { Fund } from 'shared/queries'
import { setFormError } from 'shared/services'

import {
  FundTransactionFields as SaleFields,
  getFundTransactionMutationVariables as getSaleMutationVariables,
  fundTransactionInitialValues as saleInitialValues,
  fundTransactionValidationSchema as saleValidationSchema,
} from './fund_transaction_form'
import { CREATE_FUND_SALE_MUTATION } from '../../../queries/fund_operations'
import { FUND_QUERY } from '../../../queries/funds'
import { USER_OPERATIONS_QUERY } from '../../../queries/user_operations'
import { translateGuitaError } from '../../../services/error_messages'
import {
  FundOperationFields as WithdrawalFields,
  getFundOperationInitialValues as getWithdrawalInitialValues,
  getFundOperationMutationVariables as getWithdrawalMutationVariables,
  fundOperationValidationSchema as withdrawalValidationSchema,
} from '../../fund_operation_form'

import type { FundTransactionFormValues as SaleFormValues } from './fund_transaction_form'
import type {
  CreateFundSaleData,
  CreateFundSaleVars,
} from '../../../queries/fund_operations'
import type { FundOperationFormValues as WithdrawalFormValues } from '../../fund_operation_form'
import type { FormikProps } from 'formik'

export type FormValues = {
  useMaxAmount: boolean
} & SaleFormValues
  & WithdrawalFormValues

export const getInitialValues = (fund: Fund): FormValues => ({
  useMaxAmount: false,
  ...saleInitialValues,
  ...getWithdrawalInitialValues(fund.assets),
})

export const getMutationVariables =
  (fund: Fund, userId: string, data: FormValues) => ({
    useMaxAmount: data.useMaxAmount,
    ...getSaleMutationVariables(userId, data),
    ...getWithdrawalMutationVariables(fund, data),
  })

export const validationSchema: Yup.SchemaOf<FormValues> = (
  Yup.object().shape({
    useMaxAmount: Yup.boolean()
      .required('Este campo es obligatorio'),
    ...saleValidationSchema.fields,
    ...withdrawalValidationSchema(true).fields,
  })
)

type InnerFormProps = FormikProps<FormValues> & {
  fund: Fund
  cancelCreate: () => void
}

const InnerForm = ({
  isSubmitting,
  isValid,
  setFieldTouched,
  setFieldValue,
  status,
  values,
  submitForm,
  fund,
  cancelCreate,
}: InnerFormProps) => {
  const onUseMaxAmountChange = () => {
    const newUseMaxAmount = !values.useMaxAmount
    const maxSharesAmount = (fund.balance / fund.sharePrice).toFixed(6)
    const fiatSharesAmount = (values.fiatAmount / fund.sharePrice).toFixed(6)

    setFieldValue('useMaxAmount', newUseMaxAmount)
    setFieldValue('sharesAmount', newUseMaxAmount ? maxSharesAmount : fiatSharesAmount)
    setFieldTouched('sharesAmount', false, false)
  }

  return (
    <Form>
      <Stack spacing={3}>
        <SaleFields fund={fund}>
          <Field
            component={CheckboxWithLabel}
            type='checkbox'
            name='useMaxAmount'
            onChange={onUseMaxAmountChange}
            Label={{ label: 'Usar el máximo disponible' }}
          />
        </SaleFields>
        <Divider>Retiro de activos correspondientes:</Divider>
        <WithdrawalFields />
      </Stack>
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={2}
      />
      <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isSubmitting}
            onClick={cancelCreate}
            variant='outlined'
            color='secondary'
          >
            Cancelar
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={isSubmitting || !isValid}
            onClick={submitForm}
            variant='contained'
            color='error'
          >
            Crear
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Form>
  )
}

type FundSaleCreatorProps = {
  cancelCreate: () => void
  closeDialog: () => void
  fund: Fund
  userId: string
}

export const FundSaleCreator = ({
  cancelCreate,
  closeDialog,
  fund,
  userId,
}: FundSaleCreatorProps) => {
  const fundId = fund?.id || ''
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [createFundSale] =
    useMutation<CreateFundSaleData, CreateFundSaleVars>(
      CREATE_FUND_SALE_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          USER_OPERATIONS_QUERY,
          { query: FUND_QUERY, variables: { fundId, userId } },
        ],
      })

  const handleSubmit = async (values: FormValues) => {
    const variables = getMutationVariables(fund, userId, values)
    const response = await createFundSale({ variables })

    if (response.data?.createFundSale === 'OK!') {
      closeDialog()
      return
    }

    setFormError(formRef, translateGuitaError(response))
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues(fund)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm
            {...props}
            fund={fund}
            cancelCreate={cancelCreate}
          />
        )}
      </Formik>
    </LocalizationProvider>
  )
}
