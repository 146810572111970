import { gql } from '@apollo/client'

import { IDENTITY_PROOF_FIELDS } from 'shared/queries'

import type { IdentityProof } from 'shared/queries'

export type CreateIdentityProofVars = {
  userId: string
  contentType: string
  documentType: string
  expirationDate: string | null
}

export type CreateIdentityProofData = {
  createIdentityProof: IdentityProof
}

export const CREATE_IDENTITY_PROOF_MUTATION = gql`
  ${IDENTITY_PROOF_FIELDS}
  mutation CreateIdentityProof(
    $userId: ID!
    $contentType: String!
    $documentType: String!
    $expirationDate: ISO8601Date
  ) {
    createIdentityProof(
      input: {
        userId: $userId
        contentType: $contentType
        documentType: $documentType
        expirationDate: $expirationDate
      }
    ) {
      ...IdentityProofFields
    }
  }
`

export type UpdateIdentityProofVars = {
  userId: string
  documentType: string
  expirationDate: string | null
}

export type UpdateIdentityProofData = {
  updateIdentityProof: string
}

export const UPDATE_IDENTITY_PROOF_MUTATION = gql`
  mutation UpdateIdentityProof(
    $userId: ID!
    $documentType: String!
    $expirationDate: ISO8601Date
  ) {
    updateIdentityProof(
      input: {
        userId: $userId
        documentType: $documentType
        expirationDate: $expirationDate
      }
    )
  }
`

export type DeleteIdentityProofVars = {
  id: string
  userId: string
}

export type DeleteIdentityProofData = {
  deleteIdentityProof: string
}

export const DELETE_IDENTITY_PROOF_MUTATION = gql`
  mutation DeleteIdentityProof(
    $id: ID!
    $userId: ID!
  ) {
    deleteIdentityProof(
      input: {
        id: $id
        userId: $userId
      }
    )
  }
`
