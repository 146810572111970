import { gql } from '@apollo/client'

import {
  BUSINESS_IDENTITY_FIELDS,
  ELECTRONIC_SIGNATURE_FIELDS,
  FUNDS_ORIGIN_FIELDS,
  IDENTITY_PROOF_VIEW_FIELDS,
  LEGAL_ADDRESS_FIELDS,
  LIVENESS_PROOF_VIEW_FIELDS,
  OCCUPATION_PROFILE_FIELDS,
  PERSON_IDENTITY_FIELDS,
  PHONE_NUMBER_FIELDS,
  ULTIMATE_BENEFICIAL_OWNER_FIELDS,
} from 'shared/queries'

import { RiskValue } from './risk_ratings'

import type { OnboardingType } from './domains'
import type { PaginableQuery, PaginableVars } from './pagination_helpers'
import type { VerificationState } from './verifications'
import type {
  BusinessIdentity,
  ElectronicSignature,
  FundsOrigin,
  IdentityProofView,
  LegalAddress,
  LivenessProofView,
  OccupationProfile,
  PersonIdentity,
  PhoneNumber,
  UltimateBeneficialOwner,
} from 'shared/queries'

export type User = {
  id: string
  email: string
  nickName: string
  isConfirmed: boolean
  isBusiness?: boolean
}

export const USER_DATA_FIELDS = gql`
  fragment UserFields on User {
    id
    email
    nickName
    isConfirmed
    isBusiness
  }
`

export type UsersVars = PaginableVars & {
  filter?: string
}

export type UsersData = {
  users: PaginableQuery<User>
}

export const USERS_QUERY = gql`
  ${USER_DATA_FIELDS}
  query Users(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $filter: String
  ) {
    users(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: $filter
    ) {
      edges {
        cursor
        node {
          ...UserFields
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      totalCount
    }
  }
`

type Verification = {
  id: string
  state: VerificationState
}

const VERIFICATION_FIELDS = gql`
  fragment UserVerificationFields on Verification {
    id
    state
  }
`

export type DetailedUser = User & {
  globalRiskRating?: RiskValue
  isDemoAccount: boolean
  onboardingType: OnboardingType
  verification?: Verification
  businessIdentity?: BusinessIdentity
  electronicSignature?: ElectronicSignature
  fundsOrigin?: FundsOrigin
  personIdentity?: PersonIdentity
  legalAddress?: LegalAddress
  identityProofs?: IdentityProofView[]
  livenessProofs?: LivenessProofView[]
  occupationProfile?: OccupationProfile
  phoneNumber?: PhoneNumber
  ultimateBeneficialOwners?: UltimateBeneficialOwner[]
}

export type UserVars = {
  userId: string
}

export type UserData = {
  user: DetailedUser
}

export const USER_QUERY = gql`
  ${USER_DATA_FIELDS}
  ${VERIFICATION_FIELDS}
  ${BUSINESS_IDENTITY_FIELDS}
  ${ELECTRONIC_SIGNATURE_FIELDS}
  ${FUNDS_ORIGIN_FIELDS}
  ${PERSON_IDENTITY_FIELDS}
  ${LEGAL_ADDRESS_FIELDS}
  ${IDENTITY_PROOF_VIEW_FIELDS}
  ${LIVENESS_PROOF_VIEW_FIELDS}
  ${OCCUPATION_PROFILE_FIELDS}
  ${PHONE_NUMBER_FIELDS}
  ${ULTIMATE_BENEFICIAL_OWNER_FIELDS}
  query User(
    $userId: ID!
  ) {
    user(
      userId: $userId
    ) {
      ...UserFields
      globalRiskRating
      isDemoAccount
      onboardingType
      verification {
        ...UserVerificationFields
      }
      businessIdentity {
        ...BusinessIdentityFields
      }
      electronicSignature {
        ...ElectronicSignatureFields
      }
      fundsOrigin {
        ...FundsOriginFields
      }
      personIdentity {
        ...PersonIdentityFields
      }
      legalAddress {
        ...LegalAddressFields
      }
      identityProofs {
        ...IdentityProofViewFields
      }
      livenessProofs {
        ...LivenessProofViewFields
      }
      occupationProfile {
        ...OccupationProfileFields
      }
      phoneNumber {
        ...PhoneNumberFields
      }
      ultimateBeneficialOwners {
        ...UltimateBeneficialOwnerFields
      }
    }
  }
`

export type CreateUserVars = {
  confirmEmail: boolean
  email: string
  nickName: string
  originName?: string
  isBusiness?: boolean
  password?: string
  passwordConfirmation?: string
}

export type CreateUserData = {
  createUser: {
    id: string
  }
}

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser(
    $confirmEmail: Boolean
    $email: String!
    $nickName: String!
    $originName: String
    $isBusiness: Boolean
    $password: String
    $passwordConfirmation: String
  ) {
    createUser(
      input: {
        confirmEmail: $confirmEmail
        email: $email
        nickName: $nickName
        originName: $originName
        isBusiness: $isBusiness
        password: $password
        passwordConfirmation: $passwordConfirmation
      }
    ) {
      id
    }
  }
`

export type CloneUserVars = {
  domainName: string
  userId: string
}

export type CloneUserData = {
  cloneUser: string
}

export const CLONE_USER_MUTATION = gql`
  mutation CloneUser(
    $domainName: String!
    $userId: ID!
  ) {
    cloneUser(
      input: {
        domainName: $domainName
        userId: $userId
      }
    )
  }
`
