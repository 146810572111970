import { withJsTimestamp } from 'shared/services'

import { AllFundOperation } from '../queries/fund_operations'
import { FundDetails } from '../queries/funds'

const findAssetIndex = (fund: FundDetails, assetSymbol: string) => (
  fund.currentAssets.findIndex((asset) => asset === assetSymbol)
)

export const getCurrentAssetAmount = (fund: FundDetails, asset: string) => (
  fund.currentAmounts[findAssetIndex(fund, asset)] || 0
)

export const getCurrentAssetPercentage = (fund: FundDetails, asset: string) => (
  fund.currentPercentages[findAssetIndex(fund, asset)] || 0
)

export const getCurrentAssetRebalanceAmount = (fund: FundDetails, asset: string) => (
  fund.currentRebalanceAmounts[findAssetIndex(fund, asset)] || 0
)

export const asFundOperationsArray = (fund?: FundDetails): AllFundOperation[] => {
  if (typeof fund === 'undefined') {
    return []
  }

  const operations = [
    ...fund.fundDeposits,
    ...fund.fundSwaps,
    ...fund.fundWithdrawals,
    ...fund.fundPurchases,
    ...fund.fundSales,
  ]

  return withJsTimestamp(operations)
}
