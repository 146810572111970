import { gql } from '@apollo/client'

import type { FundPurchase, FundSale } from 'shared/queries'

type FundOperationAttributes = {
  id: string
  amounts: number[]
  assets: string[]
  timestamp: number
}

export type FundDeposit = { __typename: 'FundDeposit' } & FundOperationAttributes

export const FUND_DEPOSIT_FIELDS = gql`
  fragment FundDepositFields on FundDeposit {
    id
    amounts
    assets
    timestamp
  }
`

export type FundSwap = { __typename: 'FundSwap' } & FundOperationAttributes

export const FUND_SWAP_FIELDS = gql`
  fragment FundSwapFields on FundSwap {
    id
    amounts
    assets
    timestamp
  }
`

export type FundWithdrawal = { __typename: 'FundWithdrawal' } & FundOperationAttributes

export const FUND_WITHDRAWAL_FIELDS = gql`
  fragment FundWithdrawalFields on FundWithdrawal {
    id
    amounts
    assets
    timestamp
  }
`

export type FundOperation =
  FundDeposit
  | FundSwap
  | FundWithdrawal

export type AllFundOperation = FundOperation | FundPurchase | FundSale

export type CreateFundSwapVars = {
  fundId: string
  assets: string[]
  amounts: string[]
  timestamp: string
}

export type CreateFundSwapData = {
  createFundSwap: string
}

export const CREATE_FUND_SWAP_MUTATION = gql`
  mutation CreateFundSwap(
    $fundId: ID!
    $assets: [String!]!
    $amounts: [String!]!
    $timestamp: String!
  ) {
    createFundSwap(
      input: {
        fundId: $fundId
        assets: $assets
        amounts: $amounts
        timestamp: $timestamp
      }
    )
  }
`

export type CreateFundPurchaseVars = {
  fundId: string
  userId: string
  assets: string[]
  amounts: string[]
  fiatAmount: string
  sharesAmount: string
  timestamp: string
  notifyUser: boolean
}

export type CreateFundPurchaseData = {
  createFundPurchase: string
}

export const CREATE_FUND_PURCHASE_MUTATION = gql`
  mutation CreateFundPurchase(
    $fundId: ID!
    $userId: ID!
    $assets: [String!]!
    $amounts: [String!]!
    $fiatAmount: String!
    $sharesAmount: String!
    $timestamp: String!
    $notifyUser: Boolean!
  ) {
    createFundPurchase(
      input: {
        fundId: $fundId
        userId: $userId
        assets: $assets
        amounts: $amounts
        fiatAmount: $fiatAmount
        sharesAmount: $sharesAmount
        timestamp: $timestamp
        notifyUser: $notifyUser
      }
    )
  }
`

export type CreateFundSaleVars = {
  fundId: string
  userId: string
  assets: string[]
  amounts: string[]
  fiatAmount: string
  sharesAmount: string
  timestamp: string
  notifyUser: boolean
  useMaxAmount: boolean
}

export type CreateFundSaleData = {
  createFundSale: string
}

export const CREATE_FUND_SALE_MUTATION = gql`
  mutation CreateFundSale(
    $fundId: ID!
    $userId: ID!
    $assets: [String!]!
    $amounts: [String!]!
    $fiatAmount: String!
    $sharesAmount: String!
    $timestamp: String!
    $notifyUser: Boolean!
    $useMaxAmount: Boolean!
  ) {
    createFundSale(
      input: {
        fundId: $fundId
        userId: $userId
        assets: $assets
        amounts: $amounts
        fiatAmount: $fiatAmount
        sharesAmount: $sharesAmount
        timestamp: $timestamp
        notifyUser: $notifyUser
        useMaxAmount: $useMaxAmount
      }
    )
  }
`
