import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid, Typography } from '@mui/material'

import { BackButton, FundDisplay, GridDivider, Loading, SeoHeaders } from 'shared/components'
import { FundBalance } from 'shared/views'

import { FundSwapCreator } from './fund_swap_creator'
import { FUND_DETAILS_QUERY } from '../../../queries/funds'
import { asFundOperationsArray } from '../../../services/funds'
import { FundDetailsTable } from '../../fund_details_table'
import { FundOperationsList } from '../../fund_operations_list'
import { FundUpdater } from '../../fund_updater'

import type { FundDetailsData, FundDetailsVars } from '../../../queries/funds'
import type { RouteComponentProps } from '@reach/router'

type FundDetailsProps = RouteComponentProps & {
  id?: string
}

const MainFundsDetails = (props: FundDetailsProps) => {
  const { loading, data } = useQuery<FundDetailsData, FundDetailsVars>(
    FUND_DETAILS_QUERY, {
      variables: {
        fundId: props.id || '',
      },
    })

  if (loading) {
    return <Loading />
  }

  const fund = data?.fundDetails

  return fund ? (
    <React.Fragment>
      <SeoHeaders title={`Fondos - ${fund.name}`} />
      <Grid
        container
        spacing={3}
        paddingBottom={12}
      >
        <FundBalance
          loading={false}
          descriptionText='Monto total'
          currency='USD'
          digits={2}
          value={fund.balance}
        />
        <FundBalance
          loading={false}
          descriptionText='Valor cuota'
          currency='USD'
          digits={2}
          value={fund.sharePrice}
        />
        <BackButton
          text='Fondos'
          containerProps={{ xs: true }}
        />
        <FundDisplay
          fund={fund}
          graphType='COMPOSITION'
        >
          <FundUpdater fund={fund} />
        </FundDisplay>
        <GridDivider>Situación actual</GridDivider>
        <FundDetailsTable fund={fund} />
        <GridDivider>Historial</GridDivider>
        <FundOperationsList
          operations={asFundOperationsArray(fund)}
          fund={fund}
        />
      </Grid>
      <FundSwapCreator fund={fund} />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <SeoHeaders title='Fondo no encontrado' />
      <Typography
        variant='h5'
        textAlign='center'
      >
        Fondo no encontrado
      </Typography>
    </React.Fragment>
  )
}

export default MainFundsDetails
