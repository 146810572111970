import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button, Divider, Stack } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { ButtonContainer, ButtonsContainer, ErrorDisplay } from 'shared/components'
import { Fund } from 'shared/queries'
import { setFormError } from 'shared/services'

import {
  FundTransactionFields as PurchaseFields,
  getFundTransactionMutationVariables as getPurchaseMutationVariables,
  fundTransactionInitialValues as purchaseInitialValues,
  fundTransactionValidationSchema as purchaseValidationSchema,
} from './fund_transaction_form'
import { CREATE_FUND_PURCHASE_MUTATION } from '../../../queries/fund_operations'
import { FUND_QUERY } from '../../../queries/funds'
import { USER_OPERATIONS_QUERY } from '../../../queries/user_operations'
import { translateGuitaError } from '../../../services/error_messages'
import {
  FundOperationFields as DepositFields,
  fundOperationValidationSchema as depositValidationSchema,
  getFundOperationInitialValues as getDepositInitialValues,
  getFundOperationMutationVariables as getDepositMutationVariables,
} from '../../fund_operation_form'

import type { FundTransactionFormValues as PurchaseFormValues } from './fund_transaction_form'
import type {
  CreateFundPurchaseData,
  CreateFundPurchaseVars,
} from '../../../queries/fund_operations'
import type { FundOperationFormValues as DepositFormValues } from '../../fund_operation_form'
import type { FormikProps } from 'formik'

export type FormValues = PurchaseFormValues & DepositFormValues

export const getInitialValues = (fund: Fund): FormValues => ({
  ...purchaseInitialValues,
  ...getDepositInitialValues(fund.assets),
})

export const getMutationVariables = (fund: Fund, userId: string, data: FormValues) => ({
  ...getPurchaseMutationVariables(userId, data),
  ...getDepositMutationVariables(fund, data),
})

export const validationSchema: Yup.SchemaOf<FormValues> = (
  Yup.object().shape({
    ...purchaseValidationSchema.fields,
    ...depositValidationSchema(true).fields,
  })
)

type InnerFormProps = FormikProps<FormValues> & {
  fund: Fund
  cancelCreate: () => void
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  submitForm,
  fund,
  cancelCreate,
}: InnerFormProps) => (
  <Form>
    <Stack spacing={3}>
      <PurchaseFields fund={fund} />
      <Divider>Deposito de activos correspondientes:</Divider>
      <DepositFields />
    </Stack>
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting}
          onClick={cancelCreate}
          variant='outlined'
          color='secondary'
        >
          Cancelar
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='error'
        >
          Crear
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type FundPurchaseCreatorProps = {
  cancelCreate: () => void
  closeDialog: () => void
  fund: Fund
  userId: string
}

export const FundPurchaseCreator = ({
  cancelCreate,
  closeDialog,
  fund,
  userId,
}: FundPurchaseCreatorProps) => {
  const fundId = fund?.id || ''
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [createFundPurchase] =
    useMutation<CreateFundPurchaseData, CreateFundPurchaseVars>(
      CREATE_FUND_PURCHASE_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          USER_OPERATIONS_QUERY,
          { query: FUND_QUERY, variables: { fundId, userId } },
        ],
      })

  const handleSubmit = async (values: FormValues) => {
    const variables = getMutationVariables(fund, userId, values)
    const response = await createFundPurchase({ variables })

    if (response.data?.createFundPurchase === 'OK!') {
      closeDialog()
      return
    }

    setFormError(formRef, translateGuitaError(response))
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues(fund)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm
            {...props}
            fund={fund}
            cancelCreate={cancelCreate}
          />
        )}
      </Formik>
    </LocalizationProvider>
  )
}
