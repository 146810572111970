import * as React from 'react'

import { useQuery } from '@apollo/client'

import { ONBOARDING_DOCUMENTS_QUERY } from '../../../queries/user_documents'
import UserDocumentsList from '../../user_documents_list'

import type {
  OnboardingDocumentsData,
  OnboardingDocumentsVars,
} from '../../../queries/user_documents'

type AdministrativeDocumentsProps = {
  userId: string
}

const AdministrativeDocuments = ({
  userId,
}: AdministrativeDocumentsProps) => {
  const { loading, data } =
    useQuery<OnboardingDocumentsData, OnboardingDocumentsVars>(ONBOARDING_DOCUMENTS_QUERY, {
      variables: {
        userId,
      },
    })

  return (
    <UserDocumentsList
      userId={userId}
      loading={loading}
      documents={data?.onboardingDocuments || []}
    />
  )
}

export default AdministrativeDocuments
