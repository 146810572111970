import { gql } from '@apollo/client'

import { FUNDS_FIELDS, FUND_FIELDS, FUND_PURCHASE_FIELDS, FUND_SALE_FIELDS } from 'shared/queries'

import { FUND_DEPOSIT_FIELDS, FUND_SWAP_FIELDS, FUND_WITHDRAWAL_FIELDS } from './fund_operations'

import type { FundDeposit, FundSwap, FundWithdrawal } from './fund_operations'
import type { Fund, FundPurchase, FundSale, Funds } from 'shared/queries'

export type FundsVars = {
  userId?: string
}

export type FundsData = {
  funds: Funds
}

export const FUNDS_QUERY = gql`
  ${FUNDS_FIELDS}
  query Funds(
    $userId: ID
  ) {
    funds(
      userId: $userId
    ) {
      ...FundsFields
    }
  }
`

export type FundVars = {
  fundId: string
  userId: string
}

export type FundData = {
  fund: Fund
}

export const FUND_QUERY = gql`
  ${FUND_FIELDS}
  query Fund(
    $fundId: ID!
    $userId: ID!
  ) {
    fund (
      fundId: $fundId
      userId: $userId
    ) {
      ...FundFields
    }
  }
`

export type FundDetails = Fund & {
  currentAmounts: number[]
  currentAssets: string[]
  currentPercentages: number[]
  currentRebalanceAmounts: number[]
  fundDeposits: FundDeposit[]
  fundSwaps: FundSwap[]
  fundWithdrawals: FundWithdrawal[]
  fundPurchases: FundPurchase[]
  fundSales: FundSale[]
}

const FUND_DETAILS_FIELDS = gql`
  ${FUND_DEPOSIT_FIELDS}
  ${FUND_SWAP_FIELDS}
  ${FUND_WITHDRAWAL_FIELDS}
  ${FUND_PURCHASE_FIELDS}
  ${FUND_SALE_FIELDS}
  fragment FundDetailsFields on FundDetails {
    id
    balance
    assets
    name
    percentages
    sharePrice
    symbol
    currentAmounts
    currentAssets
    currentPercentages
    currentRebalanceAmounts
    fundDeposits {
      ...FundDepositFields
    }
    fundSwaps {
      ...FundSwapFields
    }
    fundWithdrawals {
      ...FundWithdrawalFields
    }
    fundPurchases {
      ...FundPurchaseFields
    }
    fundSales {
      ...FundSaleFields
    }
  }
`

export type FundDetailsVars = {
  fundId: string
}

export type FundDetailsData = {
  fundDetails: FundDetails
}

export const FUND_DETAILS_QUERY = gql`
  ${FUND_DETAILS_FIELDS}
  query FundDetails(
    $fundId: ID!
  ) {
    fundDetails (
      fundId: $fundId
    ) {
      ...FundDetailsFields
    }
  }
`

export type CreateFundVars = {
  assets: string[]
  name: string
  percentages: string[]
}

export type CreateFundData = {
  createFund: string
}

export const CREATE_FUND_MUTATION = gql`
  mutation CreateFund(
    $assets: [String!]!
    $name: String!
    $percentages: [String!]!
  ) {
    createFund(
      input: {
        assets: $assets
        name: $name
        percentages: $percentages
      }
    )
  }
`

export type UpdateFundVars = {
  fundId: string
  assets: string[]
  percentages: string[]
}

export type UpdateFundData = {
  updateFund: string
}

export const UPDATE_FUND_MUTATION = gql`
  mutation UpdateFund(
    $fundId: ID!
    $assets: [String!]!
    $percentages: [String!]!
  ) {
    updateFund(
      input: {
        fundId: $fundId
        assets: $assets
        percentages: $percentages
      }
    )
  }
`
