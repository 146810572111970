import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Clear } from '@mui/icons-material'
import { Button, IconButton, MenuItem, Stack } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Field, Form, Formik, FormikProps } from 'formik'
import { Select } from 'formik-mui'
import { MobileDatePicker } from 'formik-mui-x-date-pickers'
import * as Yup from 'yup'

import {
  ButtonContainer,
  ButtonsContainer,
  DocumentUpload,
  ErrorDisplay,
} from 'shared/components'
import {
  BUSINESS_DOCUMENT_TYPE_LABELS,
  PERSON_DOCUMENT_TYPE_LABELS,
  POI_CONTENT_TYPE_LABELS,
} from 'shared/queries'
import {
  fromISO8601Date,
  hasValidContentType,
  hasValidSize,
  minPoiDate,
  setFormError,
  toMaybeISO8601Date,
  uploadToStorage,
} from 'shared/services'

import {
  CREATE_IDENTITY_PROOF_MUTATION,
  UPDATE_IDENTITY_PROOF_MUTATION,
} from '../../../queries/identity_proofs'
import { USER_QUERY } from '../../../queries/users'
import { translateGuitaError } from '../../../services/error_messages'

import type {
  CreateIdentityProofData,
  CreateIdentityProofVars,
  UpdateIdentityProofData,
  UpdateIdentityProofVars,
} from '../../../queries/identity_proofs'
import type { IdentityProofView } from 'shared/queries'

const documentTypeLabels = (isBusiness: boolean) => (
  isBusiness ? BUSINESS_DOCUMENT_TYPE_LABELS : PERSON_DOCUMENT_TYPE_LABELS
)

type FormValues = {
  documentType: string
  expirationDate?: Date | null
  image?: File
}

const getInitialValues = (data?: IdentityProofView): FormValues => ({
  documentType: data?.documentType || '',
  expirationDate: fromISO8601Date(data?.expirationDate),
  image: undefined,
})

const validationSchema = (isBusiness: boolean): Yup.SchemaOf<FormValues> =>
  Yup.object().shape({
    documentType: Yup.mixed()
      .oneOf(Object.keys(documentTypeLabels(isBusiness)))
      .required('Este campo es obligatorio'),
    expirationDate: (isBusiness)
      ? Yup.date()
        .nullable()
      : Yup.date()
        .typeError('Este campo es obligatorio')
        .min(minPoiDate(), 'La fecha ingresada debe ser en el futuro'),
    image: Yup.mixed()
      .test('fileType', 'Debes subir un archivo PDF/HEIC/JPG/PNG', hasValidContentType)
      .test('fileSize', 'Tu archivo supera el tamaño máximo de 16 MB', hasValidSize),
  })

type InnerFormProps = FormikProps<FormValues> & {
  isBusiness: boolean
  isPresent: boolean
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  setFieldTouched,
  setFieldValue,
  submitForm,
  isBusiness,
  isPresent,
}: InnerFormProps) => (
  <Form>
    <Stack spacing={3}>
      <Field
        required
        disabled={isPresent}
        name='documentType'
        type='text'
        label='Tipo de documento'
        component={Select}
        fullWidth
      >
        {Object.entries(documentTypeLabels(isBusiness)).map(([documentType, documentLabel]) => (
          <MenuItem
            key={documentType}
            value={documentType}
          >
            {documentLabel}
          </MenuItem>
        ))}
      </Field>
      <Field
        required
        name='expirationDate'
        type='date'
        label='Fecha de expiración'
        component={MobileDatePicker}
        minDate={minPoiDate()}
        views={['year', 'month', 'day']}
        inputFormat='yyyy/MM/dd'
        toolbarTitle='Ingresa la fecha de expiración del documento'
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={(event) => {
                setFieldValue('expirationDate', null)
                setFieldTouched('expirationDate', false)
                event.stopPropagation()
              }}
            >
              <Clear />
            </IconButton>
          ),
        }}
        closeOnSelect
        fullWidth
      />
      {!isPresent && (
        <DocumentUpload
          name='image'
          imageAlt='Imagen del documento'
          contentTypes={Object.keys(POI_CONTENT_TYPE_LABELS)}
        />
      )}
    </Stack>
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          color='error'
        >
          {isPresent ? 'Modificar' : 'Crear'}
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type IdentityProofEditFormProps = {
  closeDialog: () => void
  userId: string
  isBusiness: boolean
  identityProof?: IdentityProofView
}

const IdentityProofEditForm = ({
  closeDialog,
  userId,
  isBusiness,
  identityProof,
}: IdentityProofEditFormProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const [createIdentityProof] =
    useMutation<CreateIdentityProofData, CreateIdentityProofVars>(
      CREATE_IDENTITY_PROOF_MUTATION, {
        errorPolicy: 'all',
      })

  const [updateIdentityProof] =
    useMutation<UpdateIdentityProofData, UpdateIdentityProofVars>(
      UPDATE_IDENTITY_PROOF_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          { query: USER_QUERY, variables: { userId } },
        ],
      })

  const handleUpdate = async (values: FormValues) => {
    const response = await updateIdentityProof({
      variables: {
        userId,
        documentType: values.documentType,
        expirationDate: toMaybeISO8601Date(values.expirationDate),
      },
    })

    if (response.data?.updateIdentityProof === 'OK!') {
      closeDialog()
      return
    }

    setFormError(formRef, translateGuitaError(response))
  }

  const handleCreate = async (values: FormValues) => {
    if (!values.image) {
      setFormError(formRef, 'Debes adjuntar una imagen del documento.')
      return
    }

    const response = await createIdentityProof({
      variables: {
        userId,
        contentType: values.image.type,
        documentType: values.documentType,
        expirationDate: toMaybeISO8601Date(values.expirationDate),
      },
    })

    const storagePost = response.data?.createIdentityProof?.storagePost

    if (!storagePost) {
      setFormError(formRef, translateGuitaError(response))
      return
    }

    const storageResponse = await uploadToStorage(storagePost, values.image)

    if (!storageResponse.ok) {
      setFormError(formRef, 'Ocurrió un error al subir el archivo.')
      return
    }

    await handleUpdate(values)
  }

  const handleSubmit = async (values: FormValues) => {
    (identityProof?.id) ? await handleUpdate(values) : await handleCreate(values)
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Formik
        innerRef={formRef}
        initialValues={getInitialValues(identityProof)}
        validationSchema={validationSchema(isBusiness)}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm
            isBusiness={isBusiness}
            isPresent={!!identityProof}
            {...props}
          />
        )}
      </Formik>
    </LocalizationProvider>
  )
}

export default IdentityProofEditForm
