import { gql } from '@apollo/client'

import { BULK_PURCHASES_OVERVIEW_FIELDS } from 'shared/queries'

import { BANK_WITHDRAWAL_FIELDS, BankWithdrawal } from './bank_withdrawals'

import type { BulkPurchasesOverview } from 'shared/queries'

export type Supplier =
  'VECTOR_CAPITAL'
  | 'VITA_WALLET'
  | 'ORIONX'
  | 'MBI'
  | 'BUDA'
  | 'KOYWE'
  | 'COLOMBIA-GLOBAL66'
  | 'PGB'

export const SUPPLIER_LABELS: { [key in Supplier]: string } = {
  'VECTOR_CAPITAL': 'Vector Capital',
  'VITA_WALLET': 'Vita Wallet',
  'ORIONX': 'OrionX',
  'MBI': 'MBI',
  'BUDA': 'Buda',
  'KOYWE': 'Koywe',
  'COLOMBIA-GLOBAL66': 'Colombia (Global66)',
  'PGB': 'PGB',
}

export type SupplierBulkPurchase = {
  id: string
  inAmount: number
  paidAt: string | null
  profit: number
  profitCurrency: string
  purchasePrice: number
  supplier: string | null
  supplierAmountLeft: number
  supplierPrice: number | null
  timestamp: number
  userId: string
  vectorQuoteId: string | null
}

const SUPPLIER_BULK_PURCHASE_FIELDS = gql`
  fragment SupplierBulkPurchaseFields on SupplierBulkPurchase {
    id
    inAmount
    paidAt
    profit
    profitCurrency
    purchasePrice
    supplier
    supplierAmountLeft
    supplierPrice
    timestamp
    userId
    vectorQuoteId
  }
`

export type BulkPurchasesConciliationVars = Record<string, never>

export type BulkPurchasesConciliationData = {
  bulkPurchasesConciliation: {
    pastUnpaid: SupplierBulkPurchase[]
    todayAll: SupplierBulkPurchase[]
    unspentBankWithdrawals: BankWithdrawal[]
  }
}

export const BULK_PURCHASES_CONCILIATION_QUERY = gql`
  ${BANK_WITHDRAWAL_FIELDS}
  ${SUPPLIER_BULK_PURCHASE_FIELDS}
  query BulkPurchasesConciliationQuery {
    bulkPurchasesConciliation {
      pastUnpaid {
        ...SupplierBulkPurchaseFields
      }
      todayAll {
        ...SupplierBulkPurchaseFields
      }
      unspentBankWithdrawals {
        ...BankWithdrawalFields
      }
    }
  }
`

export type CreateBulkPurchaseVars = {
  amount: number
  purchasePrice: number
  supplier: string
  supplierPrice?: number
  profitSide: string
  timestamp?: string
  userId: string
}

export type CreateBulkPurchaseData = {
  createBulkPurchase: string
}

export const CREATE_BULK_PURCHASE_MUTATION = gql`
  mutation CreateBulkPurchase(
    $amount: Int!
    $purchasePrice: Float!
    $supplier: String!
    $supplierPrice: Float
    $profitSide: String!
    $timestamp: ISO8601DateTime
    $userId: ID!
  ) {
    createBulkPurchase(
      input: {
        amount: $amount
        purchasePrice: $purchasePrice
        supplier: $supplier
        supplierPrice: $supplierPrice
        profitSide: $profitSide
        timestamp: $timestamp
        userId: $userId
      }
    )
  }
`

export type UpdateBulkPurchaseVars = {
  bulkPurchaseId: string
  supplier: string
  supplierPrice: number
}

export type UpdateBulkPurchaseData = {
  updateBulkPurchase: string
}

export const UPDATE_BULK_PURCHASE_MUTATION = gql`
  mutation UpdateBulkPurchase(
    $bulkPurchaseId: ID!
    $supplier: String!
    $supplierPrice: Float!
  ) {
    updateBulkPurchase(
      input: {
        bulkPurchaseId: $bulkPurchaseId
        supplier: $supplier
        supplierPrice: $supplierPrice
      }
    )
  }
`

export type DeleteBulkPurchaseVars = {
  bulkPurchaseId: string
}

export type DeleteBulkPurchaseData = {
  deleteBulkPurchase: string
}

export const DELETE_BULK_PURCHASE_MUTATION = gql`
  mutation DeleteBulkPurchase(
    $bulkPurchaseId: ID!
  ) {
    deleteBulkPurchase(
      input: {
        bulkPurchaseId: $bulkPurchaseId
      }
    )
  }
`

export type TransferBulkPurchaseVars = {
  amount: number
  bulkPurchaseId: string
  receiverId: string
}

export type TransferBulkPurchaseData = {
  transferBulkPurchase: string
}

export const TRANSFER_BULK_PURCHASE_MUTATION = gql`
  mutation TransferBulkPurchase(
    $amount: Float!
    $bulkPurchaseId: ID!
    $receiverId: ID!
  ) {
    transferBulkPurchase(
      input: {
        amount: $amount
        bulkPurchaseId: $bulkPurchaseId
        receiverId: $receiverId
      }
    )
  }
`

export type BulkPurchasesOverviewVars = {
  userId: string
}

export type BulkPurchasesOverviewData = {
  bulkPurchasesOverview: BulkPurchasesOverview
}

export const BULK_PURCHASES_OVERVIEW_QUERY = gql`
  ${BULK_PURCHASES_OVERVIEW_FIELDS}
  query BulkPurchasesOverview(
    $userId: ID!
  ) {
    bulkPurchasesOverview(
      userId: $userId
    ) {
      ...BulkPurchasesOverviewFields
    }
  }
`

export type AllBulkPurchasesOverviewVars = Record<string, never>

export type AllBulkPurchasesOverviewData = {
  allBulkPurchasesOverview: BulkPurchasesOverview
}

export const ALL_BULK_PURCHASES_OVERVIEW_QUERY = gql`
  ${BULK_PURCHASES_OVERVIEW_FIELDS}
  query AllBulkPurchasesOverview {
    allBulkPurchasesOverview {
      ...BulkPurchasesOverviewFields
    }
  }
`
